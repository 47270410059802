import React from "react";
import Img from "gatsby-image";
import {graphql, Link} from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Title from "../components/Title";
import ArticleListItem from "../components/ListItems/ArticleListItem";
import Breadcrumb from "../components/Breadcrumb";
import {cssCleanClass} from "../Utils/functions";
import HtmlParser from "../components/HtmlParser";

const Article = ({data}) => {
    return (
        <Layout contentType={data.node.internal.type}>
            <Metas title={data.node.title}/>
            <section className="section page-content">
                <div className="container mx-auto px-4 lg:max-w-screen-lg">
                    <Breadcrumb/>

                    <Title title={data.node.title} className="text-primary"/>

                    {data.node.relationships.image && (
                        <div className="block figure text-center mb-8">
                            <figure className="relative">
                                <Img
                                    fluid={data.node.relationships.image.localFile.childImageSharp.fluid}
                                    alt={data.node.title}
                                />
                                <Link to={`/actualites${data.node.relationships.tags.path.alias}`} className={`tag theme theme-${cssCleanClass(data.node.relationships.tags.color)} absolute bottom-0 right-0`}>{data.node.relationships.tags.name}</Link>
                            </figure>
                        </div>
                    )}

                    {data.node.body && (
                        <div className="block content mb-8" data-typesense-field="body">
                            <HtmlParser html={data.node.body.value}/>
                        </div>
                    )}
                </div>
            </section>

            {data.otherArticles && (
                <section className="section page-content-suggestion">
                    <div className="container mx-auto px-4">
                        <h2 className="relative my-8 uppercase text-center text-primary-light strikethrough-primary-light">
                            <span className="relative inline-block px-4 bg-white">À lire aussi</span>
                        </h2>
                        <div className="columns grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            {data.otherArticles.nodes.map((node, index) => (
                                <div key={index} className={`column`}>
                                    <ArticleListItem content={node}/>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            )}
        </Layout>
    )
}

export default Article

export const query = graphql`
    query($internalId: Int!) {
        node: nodeArticle(
            status: {eq: true},
            drupal_internal__nid: {eq: $internalId}
        ) {
            title
            body {
                value
            }
            changed
            internal {
                type
            }
            relationships {
                image: field_image {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 992, maxHeight: 425, cropFocus: CENTER, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                tags: field_tags {
                    name
                    color: field_color
                    path {
                        alias
                    }
                }
            }
        }
        otherArticles: allNodeArticle(
            limit: 3,
            filter: {
              status: {eq: true}, 
              drupal_internal__nid: {ne: $internalId}
            },
            sort: {fields: created, order: DESC}
        ) {
            nodes {
                title
                body {
                    summary
                    value
                }
                path {
                    alias
                }
                relationships {
                    image: field_image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 480, maxHeight: 304, cropFocus: CENTER, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    tags: field_tags {
                        name
                        color: field_color
                        path {
                            alias
                        }
                    }
                }
            }
        }
    }
`
