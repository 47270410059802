import React from "react";
import {Link} from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";

import Small16by10 from "../Images/Small16by10";
import {cssCleanClass} from "../../Utils/functions";
import Truncate from "../Truncate";

const ArticleListItem = ({content, hideTags}) => (
    <div className="article-list-item card h-full flex flex-col">
        <div className="card-image relative flex-initial">
            <Link to={content.path.alias}>
                {content.relationships.image ? (
                    <>
                        {content.relationships.banner && (
                            <Img
                                fluid={content.relationships.banner.localFile.childImageSharp.fluid}
                                alt={content.title}
                                className="hidden md:block"
                            />
                        )}
                        <Img
                            fluid={content.relationships.image.localFile.childImageSharp.fluid}
                            alt={content.title}
                            className={`${content.relationships.banner ? `block md:hidden` : ``}`}
                        />
                    </>
                ) : (
                    <Small16by10 alt={content.title}/>
                )}
            </Link>
            {!hideTags && (
                <Link
                    to={`/actualites${content.relationships.tags.path.alias}`}
                    className={`tag theme theme-${cssCleanClass(content.relationships.tags.color)} absolute bottom-0 right-0`}
                >
                    {content.relationships.tags.name}
                </Link>
            )}
        </div>
        <div className="card-content flex-1 p-4 bg-gray-100">
            <div className="title h3 mb-2">
                <Link to={content.path.alias} className="text-primary">{content.title}</Link>
            </div>
            <Truncate
                className="content"
                limit={120}
                html={(
                    content.body.summary.length > 0
                        ? content.body.summary
                        : content.body.value
                )}
            />
        </div>
    </div>
)

ArticleListItem.propTypes = {
    content: PropTypes.object.isRequired,
}

export default ArticleListItem;
