import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const Small16by9 = ({...props}) => {
  const data = useStaticQuery(graphql`{
      file(relativePath: { eq: "placeholder.jpg" }) {
          childImageSharp {
              fluid(maxWidth: 500, maxHeight: 315, cropFocus: CENTER, quality: 100) {
                  ...GatsbyImageSharpFluid
              }
          }
      }
  }`)

  return (
    <Img fluid={data.file.childImageSharp.fluid} {...props}/>
  )
}

export default Small16by9
